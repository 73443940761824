import React from 'react';
import { motion, useAnimation } from 'framer-motion';
import { useInView } from 'react-intersection-observer';

function Skills() {
  const skills = [
    { name: "Unity", level: 90 },
    { name: "Unreal Engine", level: 85 },
    { name: "C#", level: 95 },
    { name: "C++", level: 80 },
    { name: "3D Modeling", level: 75 },
    { name: "Game Design", level: 85 },
    { name: "Level Design", level: 80 },
    { name: "Version Control", level: 85 },
    { name: "Agile Development", level: 80 }
  ];

  const [ref, inView] = useInView({
    threshold: 0.1,
    triggerOnce: false
  });

  const controls = useAnimation();

  React.useEffect(() => {
    if (inView) {
      controls.start("visible");
    } else {
      controls.start("hidden");
    }
  }, [controls, inView]);

  return (
    <section id="skills" className="min-h-screen flex items-center bg-gray-900">
      <div className="container mx-auto px-6" ref={ref}>
        <motion.h2 
          className="text-4xl md:text-6xl font-bold mb-24 pt-8 pb-4 text-center bg-clip-text text-transparent bg-gradient-to-r from-primary-400 to-secondary-400 leading-relaxed"
          initial="hidden"
          animate={controls}
          variants={{
            hidden: { opacity: 0, y: 20 },
            visible: { opacity: 1, y: 0 }
          }}
        >
          Yeteneklerim
        </motion.h2>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-6 max-w-4xl mx-auto">
          {skills.map((skill, index) => (
            <motion.div 
              key={skill.name}
              initial="hidden"
              animate={controls}
              variants={{
                hidden: { opacity: 0, x: -50 },
                visible: { 
                  opacity: 1, 
                  x: 0,
                  transition: { delay: index * 0.1 }
                }
              }}
              className="bg-gray-800 p-4 rounded-xl border border-purple-500/30"
            >
              <div className="flex justify-between items-center mb-2">
                <span className="text-purple-300">{skill.name}</span>
                <span className="text-sm text-gray-400">{skill.level}%</span>
              </div>
              <div className="h-2 bg-gray-700 rounded-full overflow-hidden">
                <motion.div
                  className="h-full bg-gradient-to-r from-primary-400 to-secondary-400"
                  initial={{ width: 0 }}
                  animate={controls}
                  variants={{
                    hidden: { width: 0 },
                    visible: { 
                      width: `${skill.level}%`,
                      transition: { duration: 1, delay: index * 0.1 }
                    }
                  }}
                />
              </div>
              <div className="flex mt-2">
                {[...Array(5)].map((_, i) => (
                  <motion.div
                    key={i}
                    className={`w-3 h-3 rounded-full mx-1 ${i < Math.floor(skill.level / 20) ? 'bg-primary-400' : 'bg-gray-600'}`}
                    initial={{ scale: 0 }}
                    animate={controls}
                    variants={{
                      hidden: { scale: 0 },
                      visible: { 
                        scale: 1,
                        transition: { delay: index * 0.1 + i * 0.1 }
                      }
                    }}
                  />
                ))}
              </div>
            </motion.div>
          ))}
        </div>
      </div>
    </section>
  );
}

export default Skills; 