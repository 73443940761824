import React from 'react';
import Hero from './components/Hero';
import Projects from './components/Projects';
import About from './components/About';
import Skills from './components/Skills';
import Contact from './components/Contact';

function App() {
  return (
    <div className="bg-gray-900 text-white relative">
      <Hero />
      <Projects />
      <About />
      <Skills />
      <Contact />
      <MobileNav />
      <div className="w-full text-center py-8 mt-[-80px] text-gray-600/50 text-sm font-medium select-none pointer-events-none backdrop-blur-sm">
        designed by IRUNBOSS
      </div>
    </div>
  );
}

function MobileNav() {
  return (
    <div className="fixed bottom-0 left-0 right-0 bg-gray-900/95 backdrop-blur-sm md:hidden z-50">
      <div className="flex justify-around items-center py-4">
        {['home', 'projects', 'about', 'skills', 'contact'].map((item) => (
          <a
            key={item}
            href={`#${item}`}
            className="text-gray-400 hover:text-primary-400 transition-colors"
          >
            {item === 'home' && '🏠'}
            {item === 'projects' && '🎮'}
            {item === 'about' && '👨‍💻'}
            {item === 'skills' && '🚀'}
            {item === 'contact' && '✉️'}
          </a>
        ))}
      </div>
    </div>
  );
}

export default App;