export const projects = [
  {
    id: 1,
    title: "Space Adventure",
    description: "Unity ile geliştirilmiş 2D uzay macera oyunu",
    image: "/images/space-game.jpg",
    technologies: ["Unity", "C#", "2D Graphics"]
  },
  {
    id: 2,
    title: "Dungeon Crawler",
    description: "Unreal Engine ile geliştirilmiş 3D aksiyon RPG",
    image: "/images/dungeon-game.jpg",
    technologies: ["Unreal Engine", "C++", "3D Modeling"]
  },
  // Daha fazla proje eklenebilir
]; 