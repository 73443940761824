import React from 'react';
import { motion } from 'framer-motion';

function About() {
  return (
    <section id="about" className="min-h-screen flex items-center">
      <div className="container mx-auto px-6">
        <motion.h2 
          className="text-4xl md:text-6xl font-bold mb-24 pt-8 pb-4 text-center bg-clip-text text-transparent bg-gradient-to-r from-primary-400 to-secondary-400 leading-relaxed"
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          viewport={{ once: true }}
        >
          Hakkımda
        </motion.h2>
        <div className="max-w-3xl mx-auto text-gray-300 text-center">
          <p className="mb-4">
            Merhaba! Ben bir oyun geliştiricisiyim ve yaratıcı projeler üretmeyi seviyorum.
            Unity ve Unreal Engine ile çalışıyorum.
          </p>
          <p>
            Amacım kullanıcılara unutulmaz oyun deneyimleri yaşatmak ve
            yenilikçi oyun mekanikleri geliştirmek.
          </p>
        </div>
      </div>
    </section>
  );
}

export default About; 