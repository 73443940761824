import React, { useState } from 'react';
import { motion } from 'framer-motion';

function Contact() {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    message: ''
  });
  const [status, setStatus] = useState('');

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setStatus('sending');
    
    try {
      // Burada bir backend API'sine form verilerini gönderebilirsiniz
      // Örnek: await axios.post('/api/contact', formData);
      
      setStatus('success');
      setFormData({ name: '', email: '', message: '' });
      setTimeout(() => setStatus(''), 3000);
    } catch (error) {
      setStatus('error');
      setTimeout(() => setStatus(''), 3000);
    }
  };

  return (
    <section id="contact" className="min-h-screen flex items-center">
      <div className="container mx-auto px-6">
        <motion.h2 
          className="text-4xl md:text-6xl font-bold mb-24 pt-8 pb-4 text-center bg-clip-text text-transparent bg-gradient-to-r from-primary-400 to-secondary-400 leading-relaxed"
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          viewport={{ once: true }}
        >
          İletişim
        </motion.h2>
        <div className="text-center mb-12">
          <motion.p
            className="text-lg text-gray-300"
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true }}
          >
            Bana doğrudan <a href="mailto:nuri.onacak@hotmail.com" className="text-purple-400 hover:text-purple-300 transition-colors">nuri.onacak@hotmail.com</a> adresinden ulaşabilirsiniz
          </motion.p>
        </div>
        <div className="max-w-lg mx-auto">
          <form onSubmit={handleSubmit} className="space-y-6">
            <div>
              <label className="block text-gray-300 mb-2">İsim</label>
              <input 
                type="text"
                name="name"
                value={formData.name}
                onChange={handleChange}
                required
                className="w-full px-4 py-2 bg-gray-800 rounded-lg focus:outline-none focus:ring-2 focus:ring-purple-600"
              />
            </div>
            <div>
              <label className="block text-gray-300 mb-2">Email</label>
              <input 
                type="email"
                name="email"
                value={formData.email}
                onChange={handleChange}
                required
                className="w-full px-4 py-2 bg-gray-800 rounded-lg focus:outline-none focus:ring-2 focus:ring-purple-600"
              />
            </div>
            <div>
              <label className="block text-gray-300 mb-2">Mesaj</label>
              <textarea 
                name="message"
                value={formData.message}
                onChange={handleChange}
                required
                className="w-full px-4 py-2 bg-gray-800 rounded-lg focus:outline-none focus:ring-2 focus:ring-purple-600"
                rows="4"
              ></textarea>
            </div>
            <button 
              type="submit"
              disabled={status === 'sending'}
              className="w-full bg-purple-600 py-3 rounded-lg hover:bg-purple-700 transition disabled:opacity-50"
            >
              {status === 'sending' ? 'Gönderiliyor...' : 'Gönder'}
            </button>
            
            {status === 'success' && (
              <p className="text-green-500 text-center">Mesajınız başarıyla gönderildi!</p>
            )}
            
            {status === 'error' && (
              <p className="text-red-500 text-center">Bir hata oluştu. Lütfen tekrar deneyin.</p>
            )}
          </form>
        </div>
      </div>
    </section>
  );
}

export default Contact; 